<template>
    <div>
        <v-system-bar
            color="grey"
            lights-out
            height="30"
            class="pl-5 pr-5"
        >
            <p class="hidden-sm-and-down mt-4">Tentang Viva</p> 
            <v-spacer></v-spacer>
            <span class="mr-5 hidden-sm-and-down">{{ this.profile ? this.profile.group : ''}}</span>
            <span class="mr-5">{{this.time }}</span>
            <span>{{ this.date_now }}</span>
        </v-system-bar>
        <v-app>
            <navigation-header-default />
            <v-main>
                <dashboard-content></dashboard-content>
            </v-main>
        </v-app>
    </div>
</template>

<script>
import NavigationHeaderDefault from './Partials/NavigationHeaderDefault.vue'
import DashboardContent from './Content.vue'
import { mapState } from 'vuex'

export default {
    name:'DefaultLayout',
    components:{
        NavigationHeaderDefault,
        DashboardContent
    },
    computed: mapState([
        'profile'
    ]),
    mounted(){
        this.$store.dispatch('getHeaderAvatar')
        // console.log(this.profile);
    },
    data() {
        return {
            show: false,
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            timeout: 7500,
            interval: null,
            time: null, 
            date_now: null,
            intervalData: null,
        }
    },
    beforeDestroy() {
    // prevent memory leak
        clearInterval(this.interval)
    },
    created() {
        // update the time every second
        this.interval = setInterval(() => {
            this.time = Intl.DateTimeFormat('sv-SE', {timeZone: 'Asia/Jakarta', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false}).format()
            this.date_now = Intl.DateTimeFormat('id', {timeZone: 'Asia/Jakarta', day: 'numeric', month: 'long', weekday: "long", year: "numeric"}).format()
        }, 1000)
    }
}
</script>