<template>
    <v-app-bar style="background-color:#0078d4;max-height: 64px;" dark sticky>
        <!-- <v-app-bar-nav-icon @click.stop="drawer()" /> -->
        <v-toolbar-title style="width: 320px" class="ml-0 pl-4">
            <router-link to="/admin">
                <v-img class="hidden-sm-and-down" src="../../../assets/logo_header.png" alt="Admin" />
            </router-link>
            <!-- <span class="hidden-sm-and-down">Viva Kencana</span> -->
        </v-toolbar-title>
        <v-spacer />
        <v-text-field flat solo-inverted hide-details append-icon="mdi-magnify" label="Search Entity" class="hidden-sm-and-down border-12 ma-0 pa-0" style="background: rgba(255, 255, 255, 0.16);" @keyup.enter="searchEntity()" v-model="search"/>
        <v-spacer />
        <!-- <v-btn class="ml-1 mr-5" icon>
            <v-icon>mdi-bell</v-icon>
            <v-badge color="green" content="2"></v-badge>
        </v-btn> -->
        <v-menu offset-y>
            <template v-slot:activator="{ on }">
                <v-toolbar-title class="pr-6" v-on="on" style="font-size:0.99rem; cursor:pointer">
                    <v-avatar size="45px" item class="mr-1">
                        <v-img :src="avatar" alt="Admin"/>
                    </v-avatar>
                    {{$store.state.user.username}}
                    <v-icon class="hidden-sm-and-down">
                        mdi-chevron-down
                    </v-icon>
                </v-toolbar-title>
            </template>
            <v-list dense>
                <v-list-item-group
                    color="primary"
                >
                    <v-list-item>
                        <v-card class="rounded-l" width="100%">
                            <v-card-text class="p-1">
                                <div class="d-flex">
                                    <v-avatar size="45px" item class="mr-2">
                                        <v-img :src="avatar" alt="Admin"/>
                                    </v-avatar>
                                    <p class="mt-3">
                                    {{ this.$store.state.profile.name1 }}
                                    </p>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-list-item>
                    <v-list-item to="/admin/dashboard" class="mt-3">
                        <v-list-item-icon>
                            <v-icon>mdi-home</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Home</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item to="/admin/profile">
                        <v-list-item-icon>
                            <v-icon>mdi-account-circle</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>User Profile</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <!-- <v-list-item  @click="cekupproval()">
                        <v-list-item-icon>
                            <v-icon>mdi-biohazard</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Approval</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item> -->
                    <v-list-item>
                        <v-btn type="submit" block class="rounded-l border-12 mr-12 pl-12 pr-12 pt-2 pb-2 mt-3" color="primary" @click="utem()">Logout</v-btn>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-menu>
        <v-dialog
            v-model="dialog"
            max-width="500px">
            <v-card>
            <v-card-title class="text-center" style="background-color:#1565C0;">
                <v-row align="center" justify="center">
                    <v-col cols="12">
                        <h5 style="font-weight:700; color: #fff;">
                        Confirm
                        </h5>
                    </v-col>
                </v-row>  
            </v-card-title>
            <v-card-text>
            <v-container fluid class="fill-height">
                <v-row>
                    <v-col cols="12" xs="12" sm="12" md="12" mb="5">
                        <h6 style="margin-top: 8px;">
                            Are you sure exit Viva-App ? 
                        </h6>
                    </v-col>
                </v-row> 
            </v-container>
        </v-card-text>
        <v-card-actions>
        <v-spacer></v-spacer>
            <v-btn color="primary" @click="logout()">Yes</v-btn>
            <v-btn color="primary" text @click="close">No</v-btn>
        </v-card-actions>
        </v-card>
        </v-dialog>
        <v-dialog
                v-model="dialogcek"
                persistent
                max-width="600px"
                hide-overlay
                transition="dialog-bottom-transition"
            >
                <v-card class="rounded-l">
                    <v-card-title class="text-center mb-0 pb-0">
                        <v-row>
                            <v-col cols="12">
                                <div class="d-flex">
                                    <div class="ml-5">
                                        <!-- {{ detail ? detail.mill_name : '' }}  # {{ detail ? detail.pr_id : '' }}  -->
                                    </div>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        class="mx-2"
                                        fab
                                        x-small
                                        color="#e83e8c;"
                                        @click="[dialogcek = false]"
                                    >
                                        <v-icon>
                                            mdi-close
                                        </v-icon>
                                    </v-btn>
                                </div>
                            </v-col>
                            <v-divider></v-divider>
                        </v-row>
                    </v-card-title>
                    <v-card-text class="ma-0">
                        <v-container class="p-4">
                            <v-row align="center" justify="center">
                                <v-col cols="8" class="text-center">
                                    <v-icon color="primary">mdi-check</v-icon>
                                    <v-btn>Purchase Order</v-btn>
                                    <!-- <h6 v-if="typeAprv == 'aprv'">
                                        Are you sure Approve this PR {{ detail ? detail.pr_id : '' }} ?
                                    </h6>
                                    <h6 v-else-if="typeAprv == 'unaprv'">
                                        Are you sure UnApprove this PR {{ detail ? detail.pr_id : '' }} ?
                                    </h6> -->
                                </v-col>
                            </v-row>
                            <v-row align="center" justify="center" class="mt-12">
                                <v-col class="col-6" sm="6" md="4">
                                    <v-btn class="border-12" block  style="padding: 21px;"
                                    elevation="2" small @click="dialogAprv = false">Cancel</v-btn>
                                </v-col>
                                <!-- <v-col class="col-6" sm="6" md="4" v-if="typeAprv == 'aprv'">
                                    <v-btn class="border-12" block style="padding: 21px;"
                                    color="info" elevation="2" small @click="submitApprove()"><v-icon>mdi-check</v-icon> Approve</v-btn>
                                </v-col>
                                <v-col class="col-6" sm="6" md="4" v-else-if="typeAprv == 'unaprv'">
                                    <v-btn class="border-12" block style="padding: 21px;"
                                    color="info" elevation="2" small @click="submitApprove()"><v-icon>mdi-close</v-icon> UnApprove</v-btn>
                                </v-col> -->
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
        </v-dialog>
        <v-progress-linear
            :active="loading"
            :indeterminate="loading"
            absolute
            bottom
            color="deep-purple accent-4"
        ></v-progress-linear>
        <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
            <v-layout align-center pr-4>
            <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
            <v-layout column>
                <div>
                <strong>{{ snackbar.title }}</strong>
                </div>
                <div>{{ snackbar.text }}</div>
            </v-layout>
            </v-layout>
            <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
            <v-icon>clear</v-icon>
            </v-btn>
        </v-snackbar>
    </v-app-bar>
</template>

<script>
import { mapState } from 'vuex'

export default {
    data() {
        return {
            loading: false,
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            timeout: 7500,
            search: '',
            text_dialog:'',
            dialog: false,
            dialogcek: false

        }
    },
    computed: mapState([
        'avatar'
    ]),
    mounted(){
        this.$store.dispatch('getHeaderAvatar')
    },
    methods: {
        searchEntity(){
            this.$store.dispatch('getEntityUser', this.search)
        },
        drawer(){
            // return this.$store.commit('SET_DRAWER_ADMIN')
        },
        checkColor(count){
            return count ? 'red' : 'green'
        },
        close(){
            this.dialog = false
            
        },
        async utem(){
            //console.log(this.dialog)
            this.dialog = true
             
        },
        async cekupproval(){
            //console.log(this.dialog)
            this.dialogcek = true
             
        },
        logout(){
            this.loading = true

            this.$store.dispatch("logoutUser").then(() => {
                this.snackbar = {
                    color: "success",
                    icon: "mdi-checkbox-marked-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Success",
                    text: "Successfully Logout Sistem",
                    visible: true
                };
                this.loading = false
                this.$router.push('/')
            });
        }
    }
}
</script>